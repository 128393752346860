/**
 * Converts a given value to a percentage string.
 *
 * @param {Number} $value - The value to be converted to a percentage.
 * @return {String} - The percentage representation of the value.
 */
/**
 * Generates a transparent version of the given color.
 *
 * @param {Color} $color - The base color to be made transparent.
 * @param {Number} $alpha - The level of transparency, ranging from 0 (fully transparent) to 1 (fully opaque). Default is 1.
 * @return {Color} - The resulting color with the specified transparency.
 */
.noUi-target {
  border: 0;
  box-shadow: none;
  background: none;
  border-radius: 0;
  color: var(--tblr-primary);
}

.noUi-horizontal {
  height: 1.25rem;
  padding: 0.5rem 0;
}

.noUi-base {
  background: var(--tblr-border-color);
  border-radius: 1rem;
}

.noUi-handle {
  width: 1rem;
  height: 1rem;
  border: 2px var(--tblr-border-style) #ffffff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background: currentColor;
  outline: 0;
}
.noUi-handle:before, .noUi-handle:after {
  content: none;
}
.noUi-horizontal .noUi-handle {
  width: 1rem;
  height: 1rem;
  top: -0.5rem;
  right: -0.5rem;
  margin: 1px 1px 0 0;
}
.noUi-handle.noUi-active, .noUi-handle:focus {
  box-shadow: 0 0 0 1px #f6f8fb, 0 0 0 0.25rem rgba(var(--tblr-primary-rgb), 0.25);
}

.noUi-connect {
  background: currentColor;
}

.litepicker {
  --litepicker-month-weekday-color: var(--tblr-secondary);
  --litepicker-button-prev-month-color: var(--tblr-secondary);
  --litepicker-button-next-month-color: var(--tblr-secondary);
  --litepicker-button-prev-month-color-hover: var(--tblr-primary);
  --litepicker-button-next-month-color-hover: var(--tblr-primary);
  --litepicker-day-color: var(--tblr-body-color);
  --litepicker-day-color-hover: var(--tblr-primary);
  --litepicker-is-start-color-bg: var(--tblr-primary);
  --litepicker-is-end-color-bg: var(--tblr-primary);
  --litepicker-is-in-range-color: var(--tblr-info);
  --litepicker-is-today-color: var(--tblr-primary);
  --litepicker-month-header-color: var(--tblr-body-color);
  --litepicker-container-months-color-bg: var(--tblr-bg-surface);
  font: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.litepicker .day-item.is-in-range {
  --litepicker-day-color: var(--tblr-light);
}
.litepicker svg {
  fill: none !important;
}
.litepicker .container__main {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  border-radius: var(--tblr-border-radius);
  box-shadow: none;
}
.litepicker .container__months .month-item-name,
.litepicker .container__months .month-item-year {
  font-weight: var(--tblr-font-weight-medium) !important;
}
.litepicker .container__months .button-next-month,
.litepicker .container__months .button-previous-month {
  cursor: pointer !important;
}
.litepicker .container__months .month-item-weekdays-row > div {
  padding: 0.5rem 0 !important;
  font-size: 0.75rem;
}
.litepicker .container__days .day-item {
  cursor: pointer !important;
  padding: 0.5rem 0 !important;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .litepicker .container__days .day-item {
    transition: none;
  }
}
.datepicker-inline .litepicker {
  box-shadow: var(--tblr-box-shadow-input);
}
.datepicker-inline .litepicker .container__months {
  box-shadow: none;
  background-color: var(--tblr-bg-forms);
}

:root {
  --ts-pr-clear-button: 0rem;
  --ts-pr-caret: 0rem;
}

.ts-input {
  color: inherit;
}

.ts-control {
  color: inherit;
}
.ts-control .dropdown-menu {
  width: 100%;
  height: auto;
}

.ts-wrapper .form-control,
.ts-wrapper .form-select, .ts-wrapper.form-control, .ts-wrapper.form-select {
  box-shadow: var(--tblr-box-shadow-input);
}
.ts-wrapper.is-invalid .ts-control, .ts-wrapper.is-valid .ts-control {
  --ts-pr-clear-button: 1.5rem;
}

.ts-dropdown {
  background: var(--tblr-bg-surface);
  color: var(--tblr-body-color);
  box-shadow: var(--tblr-box-shadow-dropdown);
  z-index: 1000;
}
.ts-dropdown .option {
  padding: 0.5rem 0.75rem;
}

.ts-control,
.ts-control input {
  color: var(--tblr-body-color);
}

.ts-control input::-moz-placeholder {
  color: #8a97ab;
}

.ts-control input::placeholder {
  color: #8a97ab;
}

.ts-wrapper.multi .ts-control > div,
.ts-wrapper.multi.disabled .ts-control > div {
  background: var(--tblr-bg-surface-secondary);
  border: 1px solid var(--tblr-border-color);
  color: var(--tblr-body-color);
}

.ts-wrapper.disabled .ts-control {
  opacity: 1;
}
.ts-wrapper.disabled .ts-control > div.item {
  color: var(--tblr-gray-500);
}

.apexcharts-tooltip {
  color: var(--tblr-light) !important;
  background: var(--tblr-bg-surface-dark) !important;
  font-size: 0.765625rem !important;
  padding: 0.25rem !important;
  box-shadow: none !important;
}

.apexcharts-tooltip-title {
  background: transparent !important;
  border: 0 !important;
  margin: 0 !important;
  font-weight: var(--tblr-font-weight-bold);
  padding: 0.25rem 0.5rem !important;
}

.apexcharts-tooltip-y-group {
  padding: 2px 0 !important;
}

.apexcharts-tooltip-series-group {
  padding: 0 0.5rem 0 !important;
}

.apexcharts-tooltip-marker:before {
  font-size: 16px !important;
}

.apexcharts-text {
  fill: var(--tblr-body-color) !important;
}

.apexcharts-gridline {
  stroke: var(--tblr-border-color) !important;
}

.apexcharts-legend-text {
  color: inherit !important;
}

.apexcharts-radialbar-track .apexcharts-radialbar-area {
  stroke: var(--tblr-border-color) !important;
}

.apexcharts-svg,
.apexcharts-canvas {
  border-radius: inherit;
}

.jvm-tooltip {
  background: #182433;
  font-family: inherit;
  font-size: 0.75rem;
  box-shadow: var(--tblr-box-shadow-card);
}

.jvm-series-container .jvm-legend .jvm-legend-title {
  border-bottom: 0;
  font-weight: var(--tblr-font-weight-medium);
}

.jvm-series-container .jvm-legend {
  background-color: var(--tblr-card-bg, var(--tblr-bg-surface));
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  min-width: 8rem;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick {
  display: flex;
  align-items: center;
}
.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-sample {
  width: 0.75rem;
  height: 0.75rem;
}

.jvm-zoom-btn {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color);
  background: var(--tblr-card-bg, var(--tblr-bg-surface));
  color: var(--tblr-body-color);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  box-shadow: var(--tblr-box-shadow-card);
}
.jvm-zoom-btn.jvm-zoomout {
  top: 2.5rem;
}

.dropzone {
  border: var(--tblr-border-width) dashed var(--tblr-border-color) !important;
  color: var(--tblr-secondary) !important;
  padding: 1rem !important;
}
.dropzone.dz-drag-hover {
  border: var(--tblr-border-width) dashed var(--tblr-primary);
  background: rgba(var(--tblr-primary-rgb), 0.01);
  color: var(--tblr-primary);
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 1;
}
.dropzone .dz-preview {
  margin: 0.5rem;
}
.dropzone .dz-preview .dz-image {
  border-radius: var(--tblr-border-radius);
}
.dropzone .dz-preview .dz-success-mark {
  height: 54px;
}

.fslightbox-container {
  font-family: inherit !important;
  background: rgba(24, 36, 51, 0.24) !important;
  -webkit-backdrop-filter: blur(4px) !important;
  backdrop-filter: blur(4px) !important;
}

.fslightbox-slide-number-container {
  color: inherit !important;
}

.fslightbox-slash {
  background: currentColor !important;
}

body {
  --plyr-color-main: var(--tblr-primary);
}

.tox-tinymce {
  border: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color) !important;
  border-radius: 6px !important;
  font-family: var(--tblr-font-sans-serif) !important;
}

.tox-toolbar__group {
  padding: 0 0.5rem 0;
}

.tox .tox-toolbar__primary {
  background: transparent !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-bottom: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color) !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.tox-tbtn {
  margin: 0 !important;
}

.tox-statusbar {
  border-top: var(--tblr-border-width) var(--tblr-border-style) var(--tblr-border-color) !important;
}

.tox .tox-toolbar-overlord,
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background: transparent !important;
}

:root {
  --gl-star-size: auto;
  --gl-star-color: var(--tblr-yellow);
  --gl-star-color-inactive: var(--tblr-border-color);
}

[data-star-rating] svg {
  width: var(--tblr-icon-size, --gl-star-size);
  height: var(--tblr-icon-size, --gl-star-size);
}
[data-star-rating] :not(.gl-active) > .gl-star-full {
  color: var(--gl-star-color-inactive) !important;
}
[data-star-rating] .gl-active > .gl-star-full {
  color: var(--gl-star-color);
  fill: currentColor;
  stroke: currentColor;
}

.clr-picker {
  box-shadow: var(--tblr-box-shadow-dropdown);
  background-color: var(--tblr-bg-surface);
}

input.clr-color {
  border-radius: var(--tblr-border-radius);
  color: var(--tblr-body-color);
  border-color: var(--tblr-border-color);
  background: transparent;
}
input.clr-color:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--tblr-primary-rgb), 0.25);
  border-color: rgba(var(--tblr-primary-rgb), 0.25);
}

.clr-swatches button {
  border-radius: var(--tblr-border-radius);
  padding: 0 2px 4px 2px;
}
.clr-swatches button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--tblr-primary-rgb), 0.25);
}

.clr-preview {
  border-radius: var(--tblr-border-radius);
  overflow: visible;
}
.clr-preview button, .clr-preview:before, .clr-preview:after {
  border-radius: var(--tblr-border-radius);
}
.clr-preview button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--tblr-primary-rgb), 0.25);
}

.clr-field {
  display: block;
}
.clr-field button {
  width: 1.5rem;
  height: 1.5rem;
  left: 6px;
  right: auto;
  border-radius: var(--tblr-border-radius);
}
.clr-field button:after {
  box-shadow: inset 0 0 0 1px var(--tblr-border-color-translucent);
}
.clr-field button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--tblr-primary-rgb), 0.25);
}
.clr-field input {
  padding-left: 2.5rem;
}

.typed-cursor {
  font-weight: 500;
  color: #6c7a91;
}

/*# sourceMappingURL=tabler-vendors.css.map */